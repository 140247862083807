var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[(!_vm.loading && _vm.pagination)?_c('CTable',_vm._b({attrs:{"data":_vm.currentOrders,"box":"","mobile-cards":"","mobile-breakpoint":"1023px","paginate":"","current-page":_vm.pagination.currentPage,"per-page":_vm.perPage,"total":_vm.pagination.total,"narrowed":"","hoverable":""},on:{"page-change":_vm.onPageChange,"row-click":_vm.onRowClick}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('orderedAt'),"width":"130","field":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.createdAt))+" ")]}}],null,false,1041675306)}),_c('o-table-column',{attrs:{"label":_vm.$t('customer'),"field":"customer.user.firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.customer.user.firstName)+" "+_vm._s(row.customer.user.lastName)+" ")]}}],null,false,2076079462)}),_c('o-table-column',{attrs:{"label":_vm.$t('merchantWastes.default'),"field":"containerOrder.merchantWaste.customName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.containerOrder.merchantWaste.customName)+" ")]}}],null,false,835410268)}),_c('o-table-column',{attrs:{"label":_vm.$t('container'),"field":"containerOrder.product.container.size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$n(row.containerOrder.product.container.size))+"m³ "+_vm._s(_vm.$t(("products.container.types." + (row.containerOrder.product.container.type))))+" "),_c('CContainerOpeningType',{staticClass:"text-xs font-normal text-gray-700",attrs:{"container":row.containerOrder.product.container}})]}}],null,false,172180949)}),_c('o-table-column',{attrs:{"label":_vm.$t('deliveryDate'),"sortable":"","width":"100","field":"containerOrder.deliveryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.containerOrder.deliveryDate))+" ")]}}],null,false,1613940528)}),_c('o-table-column',{attrs:{"label":_vm.$t('pickupDate'),"sortable":"","width":"100","field":"containerOrder.deliveryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.containerOrder.pickupDate))+" ")]}}],null,false,1432709304)}),_c('o-table-column',{attrs:{"label":_vm.$t('address.deliveryAddress'),"sortable":"","field":"deliveryAddress.postalCodeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.deliveryAddress.location.postalCode)+" "+_vm._s(row.deliveryAddress.location.city)+" ")]}}],null,false,330026057)}),_c('o-table-column',{attrs:{"label":_vm.$t('netRevenue'),"sortable":"","field":"containerOrder.totalPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$n(_vm.parsePrice(row.containerOrder.totalPrice), 'currency'))+" ")]}}],null,false,3888506126)}),_c('o-table-column',{attrs:{"label":_vm.$t('paymentStatus'),"width":"140","field":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('PaymentStatus',{attrs:{"status":row.status,"small":""}})]}}],null,false,3001752556)})],1):_c('CLoading',{staticClass:"py-6"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }