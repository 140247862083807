<template>
  <CContainer>
    <OrdersTable />
  </CContainer>
</template>

<script>
import OrdersTable from '@/ui/components/Merchant/OrdersTable.vue';

export default {
  components: {
    OrdersTable,
  },
};
</script>
